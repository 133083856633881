import React from "react";
import { unstable_HistoryRouter, useNavigate } from "react-router-dom";
import { svg } from "../../utils/svg";

const Header = ({ back = "", txt = "", override, go2Back }) => {
  const navigate = useNavigate();
  // const history = unstable_HistoryRouter();
  return (
    <div className="page_header">
      <div className="header_flex">
        {back &&
          svg("arrow_big", "", () => {
            if (override?.back) {
              override.back();
            } else {
              go2Back ? navigate(-2) : navigate(-1);
            }
          })}
        {txt && <div className="txt">{txt}</div>}
        <div className="spacer"></div>
      </div>
    </div>
  );
};

export default Header;
