// const send = new BroadcastChannel("counter_channel");
// send.postMessage({ count: newCount });

// // In Tab 2
// const receive = new BroadcastChannel("counter_channel");
// receive.onmessage = (event) => {};

// // Shared Worker
// let count = 0;
// self.onconnect = function (e) {
//   const port = e.ports[0];
//   port.onmessage = function (e) {
//     if (e.data === 'increment') {
//       count++;
//       port.postMessage(count);
//     }
//   };
// };

// // In Tab 1
// const worker = new SharedWorker('worker.js');
// const port = worker.port;
// port.start();
// port.onmessage = function (e) {
//   setCount(e.data);
// };
// port.postMessage('increment');

// // In Tab 2
// const worker = new SharedWorker('worker.js');
// const port = worker.port;
// port.start();
// port.onmessage = function (e) {
//   setCount(e.data);
// };
// port.postMessage('increment');

const broadcast = new BroadcastChannel("test_channel");

const sendBroadcast = () => broadcast.postMessage("This is a test message.");
const receiveBroadcast = (cb) =>
  (broadcast.onmessage = (event) => {
    cb();
  });
export const bc = {
  send: sendBroadcast,
  receive: receiveBroadcast,
};
