import axios from "axios";
import { discounts } from "../utils/utils";
import { logDOM } from "@testing-library/react";
import { MixPanelOtpTriggered } from "./mixpanel";

const base_url = "https://api.arva.health/api/v1/arva/";
const otp_retry = {
  phone: "",
  retry: false,
};

export const getProducts = (if_successs, if_reject) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://api.arva.health/sanity/services`,
    headers: {},
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response);
    })
    .catch((error) => {
      if_reject(error);
    });
};
// export const checkDiscount = (obj, if_successs, if_reject) => {
//
//   let found_discount = discounts.filter(
//     (el) => el.name.toUpperCase() == obj.discount.toUpperCase()
//   );
//   if_successs({ status: true, data: found_discount });
// };
export const checkDiscount = ({ discount }, if_successs, if_reject) => {
  let data = "";

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/discount/" + discount,
    headers: {},
    data: data,
  };
  try {
    axios
      .request(config)
      .then((response) => {
        if (response.data.isActive) {
          if_successs({
            status: true,
            discount_data: {
              ...response.data,
              products: response.data?.appliesTo,
              cost: response.data?.value,
              is_percent: response.data?.type == "percent",
            },
          });
        } else {
          if_successs({
            status: false,
            discount_data: {},
            error: response.data.error,
          });
        }
      })
      .catch((error) => {
        if_reject(error);
      });
    // axios
    //   .post(`${base_url}shop/check-discount`, { discount }, {})
    //   .then((response) => {
    //   })
    //   .catch((error) => {
    //   });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const checkPincode = (
  pincode,
  address,
  token,
  if_successs,
  if_reject
) => {
  try {
    let data = JSON.stringify({
      pincode,
      address,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: "https://labs.arva.health/thyrocare/pincode",
      url: "https://api.arva.health/test/check-pincode",
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const sendOtp = (
  { prefix, phone, first_name },
  if_successs,
  if_reject
) => {
  try {
    let data = JSON.stringify({
      prefix: prefix ? String(prefix) : "91",
      phone: String(phone),
      first_name,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/user/send-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    MixPanelOtpTriggered({
      is_edited: otp_retry.phone !== phone && otp_retry.phone,
      is_retriggered: otp_retry.phone === phone,
    });
    otp_retry.phone = phone;

    axios
      .request(config)
      .then((response) => {
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const verifyOtp = (
  { prefix, phone, first_name, last_name, email, otp },
  if_successs,
  if_reject
) => {
  try {
    let data = JSON.stringify({
      prefix: prefix ? String(prefix) : "91",
      phone: String(phone),
      first_name,
      last_name,
      email,
      otp,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/user/verify-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const recordOrder = (token, cart, if_successs, if_reject) => {
  let t_cart = {
    ...cart,
    amounts: { ...cart.amount },
    user: {
      ...cart.user,
      user: {
        ...cart.user.user,
        prefix: cart?.prefix || "",
        phone: cart.user.user.phone.replaceAll(" ", ""),
      },
    },
  };
  delete t_cart.amount;
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/cart/lead",
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: t_cart,
    };

    axios
      .request(config)
      .then((response) => {
        //
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const checkToken = (token, if_successs, if_reject) => {
  let data = "";

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/me",
    headers: {
      authorization: token,
    },
    data: data,
  };

  // axios.request(config)
  // .then((response) => {
  //
  // })
  // .catch((error) => {
  //
  // });
  try {
    axios
      .request(config)
      .then((response) => {
        //
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CommentRating = (callback) => {
  try {
    axios
      .post(`https://api.arva.health/api/v1/arva/sanity/reviews`, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const getAddress = (search, if_successs, if_reject) => {
  let data = JSON.stringify({
    address: search,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/address-autocomplete",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
