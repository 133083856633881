export const LocalToken = {
  set: (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    return true;
  },
  get: (key) => {
    return JSON.parse(localStorage.getItem("token"));
  },
};
export const LocalData = {
  set: (data) => {
    localStorage.setItem("appdata", JSON.stringify(data));
    return true;
  },
  get: (key) => {
    return JSON.parse(localStorage.getItem("appdata"));
  },
  userset: (token) => {
    localStorage.setItem("userdata", JSON.stringify(token));
    return true;
  },
  userget: (key) => {
    return JSON.parse(localStorage.getItem("userdata"));
  },
};
