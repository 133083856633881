import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bc } from "../../../services/BroadcastLocal";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { svg } from "../../../utils/svg";
import CustInputMuiClone from "../../../components/CustInput/CustInputMuiClone";
import { checkDiscount, CommentRating } from "../../../services/api";
import Checkout from "../../Checkout/Checkout";
import { discounts, NunberCostFormat, sanityimage } from "../../../utils/utils";
import Header from "../../../components/Header/Header";
import {
  MixPanelDiscount,
  MixPanelProductAdd,
} from "../../../services/mixpanel";
import { SanityContent } from "../../../components/Sanity/SanityContent";
import Slider from "react-slick";

const show_sku = true;

const bundel_percentage = (num) => {
  let t_num = Number(num) / 10;
  // let percent = 10 * (parseInt(t_num) + (parseInt(t_num) == t_num ? 0 : 1));
  let percent = 10 * (parseInt(t_num) + 1);
  return percent;
};

const ViewCart = ({ setcart_data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rating = useRef();
  const [IsAnim, setIsAnim] = useState(true);
  const [CurrentCount, setCurrentCount] = useState(0);
  const [ListComment, setListComment] = useState([]);
  const createArrayOfObjects = (count) => {
    count = Math.max(0, Math.floor(count));
    const resultArray = Array.from({ length: count }, (_, index) => ({
      index: index + 1,
    }));

    return resultArray;
  };
  useEffect(() => {
    CommentRating((res) => {
      if (res.status) {
        setListComment([...res.response.reviews]);
      }
    });
  }, []);
  var ratingSetting = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: IsAnim,
    speed: 1000,
    autoplaySpeed: 2500,
    swipeToSlide: true,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentCount(next);
    },
    onSwipe: () => {
      setIsAnim(false);
    },
  };

  const {
    cart,
    products,
    discount,
    bundles,
    discount_txt,
    api: { is_products },
  } = useSelector((state) => state.appState);
  console.log({ discount_txt });

  const [pageToShow, setpageToShow] = useState("cart");

  const [cartToShow, setcartToShow] = useState([]);
  const [recommendedBundel, setrecommendedBundel] = useState({});
  const [recommendedBundel_isOpen, setrecommendedBundel_isOpen] =
    useState(false);
  const [details_open, setdetails_open] = useState("");
  const [CouponInput, setCouponInput] = useState("");

  const [CouponInputErr, setCouponInputErr] = useState("");

  const [computedValues, setcomputedValues] = useState({
    total_discount: 0,
    subTotal: 0,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ProductId = searchParams.get("ProductId");

    if (ProductId) {
      toast.success("Product Added");

      dispatch({
        type: "setAppState",
        subType: "cart",
        action: "add",
        payload: { ProductId: ProductId.toUpperCase() },
      });
      navigate("/cart");
    }
  }, []);

  useEffect(() => {
    if (is_products) {
      let bundel_wrt_product = {};
      Object.values(bundles).map((curr_bundel) => {
        const { servicesIncluded, _id } = curr_bundel;
        if (servicesIncluded.length > 0) {
          servicesIncluded.map(({ _ref }) => {
            bundel_wrt_product[_ref] = [
              ...(bundel_wrt_product[_ref] ? bundel_wrt_product[_ref] : []),
              curr_bundel,
            ];
          });
        }
      });

      let already_added = [];
      console.log({ cart });

      let unique_cart = cart
        .map((el) => {
          if (already_added.includes(el.ProductId)) {
            return null;
          }
          already_added.push(el.ProductId);
          let curr_product = products[el.ProductId];
          let curr_bundle = bundles[el.ProductId];
          console.log(el.ProductId, curr_product, curr_bundle);

          if (curr_product || curr_bundle) {
            return {
              ...el,
              count: cart.filter((el1) => el1?.ProductId == el?.ProductId)
                .length,
              revertTo: el?.revertTo || [],
              ...(curr_product?._id
                ? {
                    ...curr_product,
                    recommendedBundels:
                      bundel_wrt_product[curr_product._id] || [],
                  }
                : {}),
              ...(curr_bundle?._id
                ? {
                    ...curr_bundle,
                  }
                : {}),
            };
          } else {
            return null;
          }
        })
        .filter((el) => el);
      setcartToShow(unique_cart);
    }
  }, [is_products, products, cart]);

  useEffect(() => {
    let is_bundel_in_cart = false;
    let cart_ids = cartToShow.map(({ ProductId, servicesIncluded }) => {
      return ProductId.toLowerCase();
    });
    let t_bunedl = {};
    if (!cart_ids.join(",").match("ab")) {
      if ((cart_ids.slice(-1)[0] || "") === "dc03") {
        t_bunedl = bundles.AB03;
      } else if (["dc02", "ft02"].includes(cart_ids.slice(-1)[0] || "")) {
        t_bunedl = bundles.AB02;
      } else {
        t_bunedl = bundles.AB01;
      }
    } else {
      t_bunedl = {};
    }
    setrecommendedBundel(t_bunedl);

    let t_subTotal = cartToShow?.reduce((acc, el) => {
      const { count, price } = el;
      return acc + count * price;
    }, 0);
    setcomputedValues((prev) => ({ ...prev, subTotal: t_subTotal }));
    setcart_data((prev) => ({
      ...prev,
      amount: {
        ...prev.amount,
        net: t_subTotal,
      },
    }));
  }, [cartToShow]);

  let t_total_discount = 0;
  useEffect(() => {
    if (discount) {
      checkDiscount(
        { discount: discount },
        ({ status, discount_data, toast_msg, error }) => {
          if (error) {
            MixPanelDiscount({
              is_valid: false,
              cart_count: cartToShow?.length || 0,
              order_type: [],
              code: discount,
            });
            return setCouponInputErr("The discount code is invalid.");
          }
          if (status) {
            if (discount_data?.code) {
              MixPanelDiscount({
                is_valid: true,
                cart_count: cartToShow?.length || 0,
                order_type: discount_data?.products?.map(
                  (el) =>
                    ({
                      ft: "Lab Test",
                      bc: "Doctor Consult",
                      hc: "Coaching",
                      ab: "Bundle",
                      gu: "Guide",
                    }[el.slice(0, 2).toLowerCase()] || el)
                ),
                code: discount,
              });

              setCouponInput(discount);
              cartToShow.map(({ sku, price, count }) => {
                if (
                  discount_data.products[0] == "All" ||
                  discount_data.products.includes(sku)
                ) {
                  if (discount_data.is_percent) {
                    t_total_discount +=
                      price * (discount_data.cost / 100) * count;
                  } else {
                    t_total_discount += discount_data.cost * count;
                  }
                }
              });

              if (t_total_discount) {
                setcomputedValues((prev) => ({
                  ...prev,
                  total_discount: Math.floor(t_total_discount),
                  discount_name: discount,
                }));
              } else {
                setcomputedValues((prev) => ({
                  ...prev,
                  total_discount: 0,
                  discount_name: "",
                }));
              }
              setcart_data((prev) => ({
                cart: [
                  ...cartToShow
                    .map((el) => {
                      //
                      // console.log(
                      //   discount_data.products.includes(el.ProductId),
                      //   el.price -
                      //     (discount_data.type = "percent"
                      //       ? el.price * (discount_data.cost / 100)
                      //       : discount_data.cost)
                      // );

                      return new Array(el.count).fill({
                        product_id: el.ProductId,
                        product_name: el.name,
                        product_price:
                          discount_data.products.includes(el.ProductId) ||
                          discount_data.products.includes("All")
                            ? el.price -
                              (discount_data.type = "percent"
                                ? el.price * (discount_data.cost / 100)
                                : discount_data.cost)
                            : el.price,
                        product_selling_price: el.price,
                        product_slashed_price: el.slashedPrice || 0,
                        product_type: el?.type || "test1",
                        contains:
                          el?.servicesIncluded?.length > 0
                            ? el?.servicesIncluded.map((el) => {
                                let this_product = products[el._ref];
                                return {
                                  product_id: this_product.sku,
                                  product_name: this_product.name,
                                  product_price: this_product.price,
                                  product_selling_price: this_product.price,
                                  product_slashed_price:
                                    this_product?.slashedPrice || 0,
                                  product_type: this_product?.type || "test1",
                                  contains: [],
                                };
                              })
                            : [],
                      });
                    })
                    .flat(),
                ],
                amount: {
                  ...prev.amount,
                  discount: {
                    value: t_total_discount || 0,
                    details: {
                      name: discount_data.code,
                      is_percent: discount_data.type == "percent",
                      value: discount_data.cost,
                      applied_to: discount_data.products,
                    },
                  },
                  gross: prev.amount.net - (t_total_discount || 0),
                },
              }));
              return;
            }
            setCouponInputErr(
              "The discount code is invalid. Please try again."
            );
          } else {
            if (toast_msg) {
              toast.error(toast_msg);
            }
          }
          dispatch({
            type: "setAppState",
            subType: "discount",
            payload: {
              discount: "",
            },
          });
        },
        (error) => {}
      );
    } else {
      let t_total_discount = 0;
      let t_total_discount_name = "";
      setcomputedValues((prev) => {
        setcart_data({
          cart: [
            ...cartToShow
              .map((el) => {
                return {
                  product_id: el.ProductId,
                  product_name: el.name,
                  product_price: el.price,
                  product_selling_price: el.price,
                  product_slashed_price: el.slashedPrice || 0,
                  product_type: el?.type || "test",
                  contains:
                    el?.servicesIncluded?.length > 0
                      ? el?.servicesIncluded.map((el) => {
                          let this_product = products[el._ref];

                          return {
                            product_id: this_product.sku,
                            product_name: this_product.name,
                            product_price: this_product.price,
                            product_selling_price: this_product.price,
                            product_slashed_price:
                              this_product?.slashedPrice || 0,
                            product_type: this_product?.type || "test",
                            contains: [],
                          };
                        })
                      : [],
                };
                return new Array(el.count).fill("abc");
                // .fill({
                //   product_id: el.ProductId,
                //   product_name: el.name,
                //   product_price: el.price,
                //   product_selling_price: el.price,
                //   product_slashed_price: el.slashedPrice || 0,
                //   product_type: el?.type || "test",
                //   contains:
                //     el?.servicesIncluded?.length > 0
                //       ? el?.servicesIncluded.map((el) => {
                //           let this_product = products[el._ref];
                //           return {
                //             product_id: this_product.sku,
                //             product_name: this_product.name,
                //             product_price: this_product.price,
                //             product_selling_price: this_product.price,
                //             product_slashed_price:
                //               this_product?.slashedPrice || 0,
                //             product_type: el?.type || "test",
                //             contains: [],
                //           };
                //         })
                //       : [],
                // });
              })
              .flat(1),
          ],
          amount: {
            net: prev.subTotal,
            discount: {
              value: t_total_discount || 0,
              details: {
                name: "",
                is_percent: true,
                value: 0,
                applied_to: [],
              },
            },
            gross: prev.subTotal - (t_total_discount || 0),
          },
        });
        return {
          ...prev,
          total_discount: t_total_discount,
          discount_name: t_total_discount_name,
        };
      });
    }
  }, [discount, cartToShow]);
  return (
    <div className="viewcart page_main">
      <Header
        {...{
          txt: (
            <svg
              className="logo"
              xmlns="http://www.w3.org/2000/svg"
              width="81"
              height="20"
              viewBox="0 0 81 20"
              fill="none"
            >
              <path
                d="M1.09588 19.83H4.09871C4.29701 19.83 4.52364 19.7167 4.60863 19.4901L6.28002 15.2125H15.8268L17.4981 19.4901C17.5831 19.7167 17.8098 19.83 18.0081 19.83H21.0109C21.4925 19.83 21.8324 19.4051 21.6341 18.9235L14.3253 0.339944C14.2404 0.113315 14.0137 0 13.8154 0H8.29135C8.09305 0 7.86642 0.113315 7.78143 0.339944L0.472652 18.9235C0.302681 19.3768 0.614295 19.83 1.09588 19.83ZM7.7531 11.5297L11.0676 2.91785L14.4103 11.5297H7.7531Z"
                fill="#5F1D1B"
              />
              <path
                d="M40.0975 20C40.5791 20 40.9474 19.915 40.9474 19.1501V16.8839C40.9474 16.4589 40.8341 16.3173 40.3808 16.289C38.9927 16.2323 38.936 13.5694 37.123 11.898C38.9077 10.7932 39.8992 8.95184 39.8992 6.60057C39.8992 2.63456 37.0947 0 32.5904 0H23.9502C23.6952 0 23.5253 0.141643 23.5253 0.424929V19.4051C23.5253 19.6601 23.6952 19.83 23.9502 19.83H27.0664C27.3496 19.83 27.4913 19.6601 27.4913 19.4051V13.2011H31.8822C36.2165 13.2011 34.3185 20 40.0975 20ZM27.4913 9.51841V3.68272H32.7321C34.7151 3.68272 35.9332 4.70255 35.9332 6.60057C35.9332 8.49858 34.7151 9.51841 32.7321 9.51841H27.4913Z"
                fill="#5F1D1B"
              />
              <path
                d="M49.4827 19.83H53.2787C53.477 19.83 53.7037 19.7167 53.7886 19.4901L61.664 0.906516C61.8623 0.424929 61.5224 0 61.0408 0H57.8396C57.6413 0 57.4147 0.113315 57.3297 0.339944L51.3807 15.2691L45.4317 0.339944C45.3467 0.113315 45.1201 0 44.9218 0H41.7207C41.2391 0 40.8991 0.424929 41.0974 0.906516L48.9728 19.4901C49.0578 19.7167 49.2844 19.83 49.4827 19.83Z"
                fill="#5F1D1B"
              />
              <path
                d="M59.982 19.83H62.9848C63.1831 19.83 63.4097 19.7167 63.4947 19.4901L65.1661 15.2125H74.7129L76.3842 19.4901C76.4692 19.7167 76.6959 19.83 76.8942 19.83H79.897C80.3786 19.83 80.7185 19.4051 80.5202 18.9235L73.2114 0.339944C73.1265 0.113315 72.8998 0 72.7015 0H67.1775C66.9791 0 66.7525 0.113315 66.6675 0.339944L59.3588 18.9235C59.1888 19.3768 59.5004 19.83 59.982 19.83ZM66.6392 11.5297L69.9537 2.91785L73.2964 11.5297H66.6392Z"
                fill="#5F1D1B"
              />
            </svg>
          ),
          back: true,

          go2Back: true,
        }}
      />
      <div className="body">
        <div className="global_discount">
          <SanityContent content={discount_txt || []} />
        </div>
        <div className="title">Your order</div>
        {cartToShow.length ? (
          <div className="product_group">
            {cartToShow?.map((el, i) => {
              const {
                id,
                count,
                name,
                price,
                slashedPrice,
                image,
                servicesIncluded,
              } = el;
              return (
                <div>
                  <div key={id} className="row">
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${sanityimage(image || {})})`,
                      }}
                    >
                      {/* {show_sku ? el.sku : ""} */}
                    </div>
                    <div className="name">
                      <div>{name}</div>
                      {servicesIncluded?.length > 0 ? (
                        <>
                          <div className="services_group">
                            <div className="count">
                              {`${servicesIncluded?.length} item${
                                servicesIncluded?.length > 1 ? "s" : ""
                              }`}
                            </div>
                            <div
                              className={
                                "details_txt" +
                                (details_open === `deatilsModalOpen` + i
                                  ? " close"
                                  : "")
                              }
                              onClick={() => {
                                let curr_name = `deatilsModalOpen` + i;
                                setdetails_open((prev) =>
                                  prev === curr_name
                                    ? ""
                                    : `deatilsModalOpen` + i
                                );
                              }}
                            >
                              {/* {details_open === `deatilsModalOpen` + i
                                ? "Close"
                                : "Details"} */}

                              {svg("arrow")}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="price_group">
                      <div className="quantity_flex">
                        <div
                          className="addbtn"
                          onClick={() => {
                            dispatch({
                              type: "setAppState",
                              subType: "cart",
                              action: "dec",
                              payload: { ProductId: el.sku },
                            });
                            bc.send();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M7 10H13"
                              stroke="#5F1D1B"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="txt">{count}</div>
                        <div
                          className="addbtn"
                          onClick={() => {
                            dispatch({
                              type: "setAppState",
                              subType: "cart",
                              action: "add",
                              payload: { ProductId: el.sku },
                            });
                            bc.send();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8 4.5C8.13261 4.5 8.25978 4.55268 8.35355 4.64645C8.44732 4.74021 8.5 4.86739 8.5 5V7.5H11C11.1326 7.5 11.2598 7.55268 11.3536 7.64645C11.4473 7.74021 11.5 7.86739 11.5 8C11.5 8.13261 11.4473 8.25978 11.3536 8.35355C11.2598 8.44732 11.1326 8.5 11 8.5H8.5V11C8.5 11.1326 8.44732 11.2598 8.35355 11.3536C8.25978 11.4473 8.13261 11.5 8 11.5C7.86739 11.5 7.74021 11.4473 7.64645 11.3536C7.55268 11.2598 7.5 11.1326 7.5 11V8.5H5C4.86739 8.5 4.74021 8.44732 4.64645 8.35355C4.55268 8.25978 4.5 8.13261 4.5 8C4.5 7.86739 4.55268 7.74021 4.64645 7.64645C4.74021 7.55268 4.86739 7.5 5 7.5H7.5V5C7.5 4.86739 7.55268 4.74021 7.64645 4.64645C7.74021 4.55268 7.86739 4.5 8 4.5Z"
                              fill="#5F1D1B"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="price">
                      <div>
                        <span>₹</span>
                        {price}
                      </div>
                      {slashedPrice && (
                        <div className="slashedPrice">
                          <span>₹</span>
                          {slashedPrice}
                        </div>
                      )}
                    </div>
                  </div>
                  {servicesIncluded?.length > 0 ? (
                    <div
                      className={
                        "bundel_details" +
                        (details_open === `deatilsModalOpen` + i
                          ? " active"
                          : "")
                      }
                    >
                      <div className="bundel_details_main">
                        {servicesIncluded.map(({ _ref }) => {
                          let curr_product = products[_ref];
                          return (
                            <div className="product_item">
                              <div
                                className="img"
                                style={{
                                  backgroundImage: `url(${sanityimage(
                                    curr_product?.image || {}
                                  )})`,
                                }}
                              ></div>
                              <div className="name_group">
                                <div className="name">{curr_product.name}</div>
                              </div>
                              <div className="price_group">
                                <div className="price">
                                  <span>₹</span>
                                  {curr_product.price}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="product_group">
            <div className="empty_cart">
              <div className="txt">It's lonely in here</div>
              <a href="https://www.arva.health/" target="_self" className="btn">
                {" "}
                Explore
              </a>
            </div>
          </div>
        )}
        {recommendedBundel?._id && false ? (
          <div className="bundel_group">
            <div className="bundel_group_main">
              <div className="header">
                <div>Save with bundles</div>
                <div>
                  Upto{" "}
                  {bundel_percentage(
                    ((recommendedBundel.slashedPrice -
                      recommendedBundel.price) /
                      recommendedBundel.slashedPrice) *
                      100
                  )}
                  % OFF
                </div>
              </div>
              <div className="details_group">
                <div className="product_item main_bundel">
                  <div
                    className="img"
                    style={{
                      // backgroundImage: `url(${recommendedBundel?.image?._upload?.previewImage})`,
                      backgroundImage: `url(${sanityimage(
                        recommendedBundel?.image
                      )})`,
                    }}
                  >
                    {/* {show_sku ? recommendedBundel?.sku : ""} */}
                  </div>
                  <div className="name_group">
                    <div className="name">{recommendedBundel.name}</div>
                    <div className="count">
                      {recommendedBundel.servicesIncluded.length} items
                    </div>
                  </div>
                  <div className="price_group">
                    <div className="price">
                      <span>₹</span>
                      {recommendedBundel.price}
                    </div>
                    {recommendedBundel.slashedPrice && (
                      <div className="slashedPrice">
                        <span>₹</span>
                        {recommendedBundel.slashedPrice}
                      </div>
                    )}
                  </div>
                </div>

                <div className="detail_body">
                  <div
                    className="deatils_accord_header"
                    onClick={() => setrecommendedBundel_isOpen((prev) => !prev)}
                  >
                    <div>Details</div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        style={{
                          transform: `rotate(${
                            recommendedBundel_isOpen ? 180 : 0
                          }deg)`,
                        }}
                      >
                        <g opacity="0.6">
                          <path
                            opacity="0.7"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.29279 7.29308C5.48031 7.10561 5.73462 7.00029 5.99979 7.00029C6.26495 7.00029 6.51926 7.10561 6.70679 7.29308L9.99979 10.5861L13.2928 7.29308C13.385 7.19757 13.4954 7.12139 13.6174 7.06898C13.7394 7.01657 13.8706 6.98898 14.0034 6.98783C14.1362 6.98668 14.2678 7.01198 14.3907 7.06226C14.5136 7.11254 14.6253 7.18679 14.7192 7.28069C14.8131 7.37458 14.8873 7.48623 14.9376 7.60913C14.9879 7.73202 15.0132 7.8637 15.012 7.99648C15.0109 8.12926 14.9833 8.26048 14.9309 8.38249C14.8785 8.50449 14.8023 8.61483 14.7068 8.70708L10.7068 12.7071C10.5193 12.8946 10.265 12.9999 9.99979 12.9999C9.73462 12.9999 9.48031 12.8946 9.29279 12.7071L5.29279 8.70708C5.10532 8.51955 5 8.26525 5 8.00008C5 7.73492 5.10532 7.48061 5.29279 7.29308Z"
                            fill="#5F1D1B"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div
                    className="details_products"
                    style={{
                      height: recommendedBundel_isOpen ? "fit-content" : "0",
                    }}
                  >
                    <div>
                      {recommendedBundel.servicesIncluded.map(({ _ref }) => {
                        let curr_product = products[_ref];
                        return (
                          <div className="product_item">
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url(${sanityimage(
                                  curr_product?.image || {}
                                )})`,
                              }}
                            >
                              {/* {show_sku ? curr_product?.sku : ""} */}
                            </div>
                            <div className="name_group">
                              <div className="name">{curr_product?.name}</div>
                            </div>
                            <div className="price_group">
                              <div className="price">
                                <span>₹</span>
                                {curr_product.price}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="btn"
                    onClick={() => {
                      let relatedProductsId =
                        recommendedBundel?.servicesIncluded?.map(
                          (el) => products[el._ref].sku
                        );
                      let relatedProductsId_copy = [...relatedProductsId];
                      let products_to_remove = [];
                      let new_productList = [
                        ...cart,
                        // .filter(({ ProductId }) => {
                        //   if (relatedProductsId_copy.includes(ProductId)) {
                        //     relatedProductsId_copy =
                        //       relatedProductsId_copy.filter(
                        //         (el) => el !== ProductId
                        //       );
                        //     products_to_remove.push({ ProductId });
                        //     return false;
                        //   }
                        //   return true;
                        // }
                        // ),
                        {
                          ProductId: recommendedBundel.sku,
                          // revertTo: [...products_to_remove],
                          revertTo: [],
                        },
                      ];
                      dispatch({
                        type: "setAppState",
                        subType: "cart",
                        action: "set",
                        payload: { cart: new_productList },
                      });
                    }}
                  >
                    Add
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {cartToShow.length ? (
          <>
            <div className="divider"></div>
            <div className="total_group">
              {[
                ...(computedValues.total_discount
                  ? [
                      {
                        name: "Subtotal",
                        cust_class: "subtotal",
                        value: "₹" + NunberCostFormat(computedValues.subTotal),
                      },
                      {
                        name: (
                          <>
                            Discount
                            <span className="fc_green">
                              {` (${computedValues.discount_name})`}
                            </span>
                          </>
                        ),
                        cust_class: "discount",
                        value: (
                          <span className="fc_green">
                            - ₹{NunberCostFormat(computedValues.total_discount)}
                          </span>
                        ),
                      },
                    ]
                  : []),
                {
                  name: "Total",
                  cust_class: "total",
                  value:
                    "₹" +
                    NunberCostFormat(
                      computedValues.total_discount
                        ? computedValues.subTotal -
                            computedValues.total_discount <
                          0
                          ? 0
                          : computedValues.subTotal -
                            computedValues.total_discount
                        : computedValues.subTotal
                    ),
                },
              ].map(({ name, cust_class, value }, i) =>
                name ? (
                  <div {...{ className: cust_class.toLowerCase() }} key={i}>
                    <div>{name}</div>
                    <div className="value">{value}</div>
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
            <div className="divider"></div>
            <div className="coupons_group">
              <div className="title">Discount</div>
              {computedValues?.discount_name ? (
                <div className="coupon_seleted">
                  <div className="coupon_group">
                    <div className="txt">{`'${computedValues.discount_name}' applied`}</div>
                    <div
                      className="remove"
                      onClick={() => {
                        setCouponInput("");
                        dispatch({
                          type: "setAppState",
                          subType: "discount",
                          payload: {
                            discount: "",
                          },
                        });
                        setCouponInput("");
                        toast.success("Discount Removed");
                      }}
                    >
                      Remove
                    </div>
                  </div>
                  <div className="yay">
                    Yay! ₹{computedValues.total_discount} total saved.
                  </div>
                </div>
              ) : (
                <CustInputMuiClone
                  {...{
                    unique_id: "inp1",
                    attr: {
                      error: CouponInputErr,
                      error_msg: CouponInputErr,
                      value: CouponInput,
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          setCouponInputErr("");
                          setCouponInput(target.value.toUpperCase());
                        },
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      placeholder: "Enter Code",
                      suffix: (
                        <span
                          className={CouponInput ? "fc_base fw_b" : ""}
                          onClick={() => {
                            if (CouponInput) {
                              dispatch({
                                type: "setAppState",
                                subType: "discount",
                                payload: {
                                  discount: CouponInput,
                                },
                              });
                              return;
                            }
                          }}
                        >
                          Apply
                        </span>
                      ),
                    },
                  }}
                />
              )}
            </div>
          </>
        ) : (
          ""
        )}
        <Slider ref={rating} {...ratingSetting} className="ratings_slider">
          {ListComment?.map((el, i) => {
            return (
              <div className="rating_box_main">
                <div className="rating_box" key={i}>
                  <div
                    style={{
                      display: "flex",
                      gap: "0 4px",
                      alignItems: "center",
                    }}
                  >
                    {createArrayOfObjects(el.star)?.map((el1, i1) => (
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        key={i1}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2730_969)">
                          <path
                            d="M6 1L7.69959 4.2918L11.5 4.81966L8.75 7.38197L9.39919 11L6 9.2918L2.60081 11L3.25 7.38197L0.5 4.81966L4.30041 4.2918L6 1Z"
                            fill="#FFA977"
                            stroke="#FFA977"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2730_969">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    ))}
                  </div>
                  <div className="rating_box_title">{el.title}</div>
                  <div className="rating_box_desc">{el.description}</div>
                  <div className="rating_box_name">{el.name}</div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="rating_dots_container">
          {ListComment?.map((el, i) => {
            return (
              <div
                className={
                  "rating_dots " +
                  (CurrentCount == i ? "rating_dots_active" : "")
                }
                onMouseEnter={() => {
                  rating.current.slickPause();
                }}
                onMouseLeave={() => {
                  rating.current.slickPlay();
                }}
                onClick={() => {
                  setCurrentCount(i);
                  rating.current.slickGoTo(i);
                  setIsAnim(false);
                  // rating.current.slickPause(i);
                }}
              ></div>
            );
          })}
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div
            className="btn"
            onClick={() => {
              //
              if (cartToShow.length) {
                dispatch({
                  type: "setAppState",
                  subType: "computedCart",
                  payload: {
                    computed_cart: { cartToShow, computedValues },
                  },
                });
                navigate("/checkout");
              }
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCart;
