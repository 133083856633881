import React, { useEffect } from "react";

export const Loader = ({ loading }) => {
  return (
    <div className="loader">
      <div className="loader_main">
        <div className="svg_floater_main">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="65"
            viewBox="0 0 64 65"
            fill="none"
          >
            <path
              d="M64 32.9972C64 50.6712 49.674 64.9972 32 64.9972C14.326 64.9972 0 50.6712 0 32.9972C0 15.3232 14.326 0.997192 32 0.997192V4.99719C16.536 4.99719 4 17.5332 4 32.9972C4 48.4612 16.536 60.9972 32 60.9972C47.464 60.9972 60 48.4612 60 32.9972H64Z"
              fill="#5F1D1B"
              // fill="#1D1D1D"
            />
          </svg>
        </div>
        {window.location.pathname.split("/")[1] !== "admin" &&
          loading !== "type1" && (
            <>
              {/* <div className="txt1">Your order’s being processed.</div> */}
              <div className="txt1">Your order is being processed.</div>
              {/* <div className="txt2">You will be automatically redirected.</div> */}
              <div className="txt2">Please do not close the page.</div>
            </>
          )}
      </div>
    </div>
  );
};
