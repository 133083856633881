import { checkToken } from "../services/api";
import { LocalData } from "../services/localStorage";
import {
  MixPanelDiscount,
  MixPanelProductAdd,
  MixPanelUsertype,
} from "../services/mixpanel";
// import { discounts } from "../utils/utils";

const default_data = {
  app_update: false,
  cart: [],
  computed_cart: {},
  discount: "",
  products: {},
  bundles: {},
  api: {
    is_products: false,
    is_bundles: false,
  },
  checkout: {},
};
export const data = {
  ...default_data,
};
const AppState = (state = data, { type, subType, action, payload }) => {
  const setLocalData = (obj) => {
    LocalData.set({
      cart: state.cart,
      discount: state.discount,
      checkout: state.checkout,
      ...obj,
    });
  };

  let localData = {};
  if (!state.app_update) {
    localData = LocalData.get();
    if (localData?.checkout?.token) {
      checkToken(
        localData.checkout.token,
        (user_data) => {
          console.log({
            user: user_data,
          });
          MixPanelUsertype({
            user_id: user_data?.customer?._id || "",
            name: `${user_data?.customer?.user?.first_name || ""} ${
              user_data?.customer?.user?.last_name || ""
            }`.trim(),
            date_of_birth: user_data?.customer?.user?.dob || "",
            user_current_goal:
              user_data?.customer?.intake?.reproductive_health?.goal || "",
            is_paid_user:
              !!user_data?.customer?.intake?.personalize
                ?.where_did_you_hear_about_us || "",
            reported_symptoms:
              user_data?.customer?.intake?.menstrual_health
                ?.experience_symptoms || "",
            reported_conditions:
              user_data?.customer?.intake?.menstrual_health?.symptoms || "",
            is_ultrasound_done:
              user_data?.customer?.intake?.reproductive_health
                ?.ultrasound_diagnosis || "",
          });
        },
        () => {}
      );
    }
    state = { ...state, ...localData, app_update: true };
  }

  switch (type) {
    case "setAppState":
      switch (subType) {
        case "cart":
          switch (action) {
            case "add": {
              console.log("add");
              let new_cart = [...state.cart, { ProductId: payload.ProductId }];
              let obj = { ...state, cart: new_cart };
              MixPanelProductAdd({
                cta_text: payload.ProductId.toLowerCase().match("ft")
                  ? "Book Now,schedule anytime"
                  : "Book Now",
                cta_position: "website",
                selected_test_package: payload.ProductId,
                cart_count: obj.cart.length,
                order_type: {
                  ft: "Lab Test",
                  bc: "Doctor Consult",
                  hc: "Coaching",
                  ab: "Bundle",
                  gu: "Guide",
                }[payload.ProductId.slice(0, 2).toLowerCase()],
              });
              setLocalData({
                cart: new_cart,
              });
              return obj;
            }
            case "set": {
              let new_cart = [...payload.cart];
              let obj = { ...state, cart: new_cart };
              let current_product = payload.cart.slice(-1)[0];
              console.log(current_product);
              MixPanelProductAdd({
                cta_text: current_product.ProductId.toLowerCase().match("ft")
                  ? "Book Now,schedule anytime"
                  : "Book Now",
                cta_position: "website",
                selected_test_package: current_product.ProductId,
                cart_count: obj.cart.length,
                order_type: {
                  ft: "Lab Test",
                  bc: "Doctor Consult",
                  hc: "Coaching",
                  ab: "Bundle",
                  gu: "Guide",
                }[current_product.ProductId.slice(0, 2).toLowerCase()],
              });
              setLocalData({
                cart: new_cart,
              });
              return obj;
            }

            case "dec": {
              let found = false;

              let new_cart = [
                ...state.cart.map((el) => {
                  const { ProductId, revertTo } = el;
                  if (!found && ProductId == payload.ProductId) {
                    found = true;
                    if (revertTo?.length) {
                      return [...revertTo];
                    } else {
                      return null;
                    }
                  }
                  return { ...el };
                }),
              ]
                .filter((el) => el)
                .flat();

              let obj = { ...state, cart: new_cart };
              setLocalData({
                cart: new_cart,
              });
              return obj;
            }
            case "clear": {
              let new_cart = [];
              let obj = { ...state, cart: new_cart };
              setLocalData({
                cart: new_cart,
              });
              return obj;
            }

            default:
              break;
          }
        case "products":
          return {
            ...state,
            ...payload,
            api: { ...state.api, ...payload?.api },
          };
        case "discount":
          // setLocalData({
          //   discount: payload.discount,
          // });
          return {
            ...state,
            discount: payload.discount,
          };
        case "computedCart":
          // setLocalData({});
          return {
            ...state,
            computed_cart: payload.computed_cart,
          };
        case "checkout":
          let current_checkout = { ...payload.checkout };
          delete current_checkout.phone_code;
          delete current_checkout.checkoutStep;
          if (
            Object.values(current_checkout).filter((el) =>
              el?.toString()?.trim()
            ).length
          ) {
            setLocalData({
              checkout: payload.checkout,
            });
            return {
              ...state,
              checkout: payload.checkout,
            };
          } else {
            return {
              ...state,
            };
          }

        default:
          return state;
      }
    case "UpdateAppStateFromLocalStorage":
      localData = LocalData.get();

      if (localData?.checkout?.token) {
        checkToken(
          localData.checkout.token,
          (user_data) => {
            MixPanelUsertype({
              user_id: user_data?.customer?._id || "",
              name: `${user_data?.user?.user?.first_name || ""} ${
                user_data?.user?.user?.last_name || ""
              }`.trim(),
              // date_of_birth: success?.customer?.user?.dob || "",
              is_test_user: false,
              is_paid_user: false,
            });
          },
          (err) => {}
        );
      }
      return { ...state, ...localData, app_update: true };
    default:
      return state;
  }
};
export default AppState;
