import React, { useEffect } from "react";
import { Loader } from "../../components/Loader/Loader";

const Purchase = () => {
  useEffect(() => {
    setTimeout(() => {
      window.open(localStorage.getItem("next_redirect"), "_self");
    }, 1000);
  }, []);
  return <div></div>;
};

export default Purchase;
