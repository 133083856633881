import React, { useEffect, useLayoutEffect, useState } from "react";

import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./Pages/Login/Login";
import Error from "./Pages/Error/Error";
import ViewCart from "./Pages/Cart/View/ViewCart";
import { bc } from "./services/BroadcastLocal";
import { getProducts } from "./services/api";
import Checkout from "./Pages/Checkout/Checkout";
import Purchase from "./Pages/Purchase/Purchase";
import GetCart from "./Pages/GetCart/GetCart";
import { Loader } from "./components/Loader/Loader";
// import Testing from "./Pages/Testing/Testing";
import ReactGA from "react-ga4";
const Layout = () => {
  const dispatch = useDispatch();
  const [discount_txt, setdiscount_txt] = useState("");

  const { computed_cart, checkout } = useSelector((state) => state.appState);

  const [cart_data, setcart_data] = useState({
    stage: "Pre-OTP",
    user: {
      user: {
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        gst: "",
      },
      address: {
        pin_code: "",
        address: "",
        apartment: "",
        landmark: "",
        city: "",
      },
      user_id: "",
    },
    cart: [],
    payment: {
      razorpay_payment_id: "",
      razorpay_order_id: "",
      razorpay_signature: "",
      status_code: "",
    },
    amounts: {
      net: 100,
      discount: {
        value: 20,
        details: {
          name: "Welcome20",
          is_percent: true,
          value: 20,
          applied_to: ["AB01"],
        },
      },
      gross: 80,
    },
  });
  const [is_loading, setis_loading] = useState(false);
  const nav = [
    {
      title: "Cart",
      path: "cart",
      element: <ViewCart {...{ setcart_data, setis_loading }} />,
      svg: "",
    },
    {
      title: "Checkout",
      path: "checkout",
      element: <Checkout {...{ cart_data, setcart_data, setis_loading }} />,
      svg: "",
    },
    {
      title: "Purchase",
      path: "purchase",
      element: <Purchase />,
      svg: "",
    },
    {
      title: "get-cart",
      path: "lkamncfoknasfgvonafdgvo",
      element: <GetCart />,
      svg: "",
    },
    // {
    //   title: "Testing",
    //   path: "testing",
    //   element: <Testing />,
    //   svg: "",
    // },
  ];
  const routes = [
    {
      path: "",
      element: <Login />,
    },
    ...nav,
    {
      path: "/*",
      element: <Error />,
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      getProducts(
        (response) => {
          let products = {};
          let products_id = {};
          let bundles = {};
          let relation_bundles_to_products = {};
          response.data.explore.map((el) => {
            const { sku, servicesIncluded, _id } = el;
            if (servicesIncluded) {
              bundles[sku] = { ...el };
              bundles[_id] = { ...el };
            } else {
              products[sku] = { ...el };
              products[_id] = { ...el };
            }
          });
          dispatch({
            type: "setAppState",
            subType: "products",
            payload: {
              products,
              bundles,
              discount_txt: response?.data?.code || "",
              api: {
                is_products: true,
                is_bundles: true,
              },
            },
          });
        },
        (error) => {}
      );
    }, 0);
  }, []);
  function scrollToTop() {
    console.log("top");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  }
  useLayoutEffect(() => {
    // bc.receive(() => {
    dispatch({
      type: "UpdateAppStateFromLocalStorage",
      subType: "",
      action: "update",
    });
    dispatch({
      type: "UpdateUserDataFromLocalStorage",
      subType: "",
      action: "update",
    });
    // setInterval(() => {
    // scrollToTop();
    // }, 1000);
    // });
  }, []);
  const [address, setAddress] = useState("");
  const [inp, setinp] = useState("");
  return (
    <div className="layout">
      {inp}

      {/* <Navigation {...{ nav }} /> */}
      {useRoutes(routes)}
      {/* <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      {is_loading ? <Loader /> : ""}
    </div>
  );
};

export default Layout;
