import React, { useEffect, useLayoutEffect, useState } from "react";
import CustInputMuiClone from "../../components/CustInput/CustInputMuiClone";
import { svg } from "../../utils/svg";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { discounts, flags, input_title } from "../../utils/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPincode,
  getAddress,
  recordOrder,
  sendOtp,
  verifyOtp,
} from "../../services/api";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import axios from "axios";
import CustPhoneCountries from "../../components/CustInput/CustPhoneCountries";
import { esES } from "@mui/x-date-pickers/locales";
import {
  Fb,
  MixPanelBookingCompleted,
  MixPanelCompletedOrder,
  MixPanelOtpEntered,
  MixPanelOtpVerified,
  MixPanelPincodeCheckRequested,
  MixPanelSignInComplete,
  MixPanelUsertype,
  MixPanelZipCode,
} from "../../services/mixpanel";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import { LocalData } from "../../services/localStorage";

const Checkout = ({ cart_data, setcart_data, setis_loading }) => {
  // //

  const { computed_cart, checkout } = useSelector((state) => state.appState);
  // // //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setuserData] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    date_of_birth: "",
    pincode: "",
    address: "",
    apt: "",
    landmark: "",
    city: "",
    state: "",
    gst: "",
    is_pincode_validated: "",
    token: "",
    phone_code: {
      flag_code: "in",
      code: "+91",
      name: "India",
    },
    // first_name: "a",
    // last_name: "b",
    // email: "a@b.com",
    // phone: "12345 12345",
    // date_of_birth: "",
    // pincode: "",
    // address: "",
    // apt: "",
    // landmark: "",
    // city: "",
    // gst: "",
  });
  // // //
  const [userDataerr, setuserDataerr] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    date_of_birth: "",
    pincode: "",
    address: "",
    apt: "",
    landmark: "",
    city: "",
    gst: "",
  });
  const [opt_error, setopt_error] = useState(false);
  // const [phone_code, setphone_code] = useState({
  //   flag_code: "in",
  //   code: "+91",
  //   name: "India",
  // });
  const phone_code = userData.phone_code;
  const setphone_code = (obj) => {
    setuserData((prev) => ({
      ...prev,
      phone_code: {
        ...obj,
      },
    }));
  };
  const [pincode_loading, setpincode_loading] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [error_otp, seterror_otp] = useState("");
  const [checkoutStep, setcheckoutStep] = useState(0);
  // // //
  const [addressdebo, setaddressdebo] = useState("");
  const [AddressList, setAddressList] = useState([]);

  useEffect(() => {
    if (!computed_cart.computedValues) {
      navigate("/cart");
    } else {
      if (checkout.first_name) {
        setuserData(checkout);
        // setuserDataerr((prev) => ({
        //   ...prev,
        //   date_of_birth: checkout.date_of_birth ? false : true,
        // }));
        setcheckoutStep(checkout.checkoutStep);
      }
    }
  }, [computed_cart]);
  useLayoutEffect(() => {
    if (Object.values(userData).filter((el) => String(el).trim()).length) {
      dispatch({
        type: "setAppState",
        subType: "checkout",
        payload: {
          checkout: {
            ...userData,
            checkoutStep,
          },
        },
      });
    }
  }, [userData, checkoutStep]);
  useEffect(() => {
    if (userData.pincode.length == 6 && checkoutStep == 3) {
      setpincode_loading(true);
      checkPincode(
        userData.pincode,
        [userData.apt, userData.address, userData.city, userData.state],
        userData.token,
        ({ servicable, district, state }) => {
          setpincode_loading(false);
          // if (api) {

          setuserData((prev) => ({
            ...prev,
            city: district,
            state: state,
          }));
          setuserDataerr((prev) => ({
            ...prev,
            pincode: !servicable,
          }));
          //
          if (servicable) {
            MixPanelPincodeCheckRequested({
              pincode: userData.pincode,
              is_serviceable: true,
            });
            setcheckoutStep(4);
            setuserData((prev) => ({ ...prev, is_pincode_validated: true }));
            recordOrder(
              userData.token,
              {
                ...cart_data,

                stage: "Post-Pin-Code",
                prefix: phone_code.code.replaceAll("+", ""),
              },

              ({ status, message }) => {},
              (err) => {}
            );
          } else {
            MixPanelPincodeCheckRequested({
              pincode: userData.pincode,
              is_serviceable: false,
            });
            setcheckoutStep(3);
            setuserData((prev) => ({ ...prev, is_pincode_validated: false }));
          }
          // //
          // return;
          // }
        },
        (error) => {
          setpincode_loading(false);
          setuserData((prev) => ({ ...prev, is_pincode_validated: false }));
          // //
        }
      );
    }
  }, [userData.pincode]);
  // console.log(cart_data);

  const handleInputChange = (index, value) => {
    if (index == 3) {
      value = value[0];
    }
    let updatedValues = [...otpValues];
    if (value?.length > 1) {
      let t_value = value.split("");
      updatedValues[0] = t_value[0] || "";
      updatedValues[1] = t_value[1] || "";
      updatedValues[2] = t_value[2] || "";
      updatedValues[3] = t_value[3] || "";
    } else {
      updatedValues[index] = value;
    }
    setOtpValues(updatedValues);
    seterror_otp("");
    if (value && index < otpValues.length - 1) {
      const nextInput = document.getElementsByName(`otp-${index + 1}`)[0];
      nextInput.focus();
    }

    // //
    if (
      updatedValues[0] &&
      updatedValues[1] &&
      updatedValues[2] &&
      updatedValues[3]
    ) {
      // setcheckoutStep(2);
      // if (userData.pincode.length == 6) {
      verifyOtp(
        {
          prefix: phone_code.code.replaceAll("+", ""),
          phone: userData.phone.replaceAll(" ", "").trim(),
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          otp: updatedValues.join(""),
        },
        ({ status, message, token, user }) => {
          if (status) {
            MixPanelOtpEntered();
            setuserData((prev) => ({ ...prev, token }));
            setcheckoutStep(3);
            if (message) {
              toast.success(message);
            }

            setuserData({
              user_id: user._id,
              first_name: user.user.first_name || "",
              last_name: user.user.last_name || "",
              email: user.user.email || "",
              phone: user.user.phone || "",
              date_of_birth: user.user.dob || "",
              pincode: user.address.pin_code || "",
              address: user.address.address || "",
              apt: user.address.apt || "",
              landmark: user.address.landmark || "",
              city: user.address.city || "",
              gst: user.address.gst || "",
              is_pincode_validated: "",
              token: token,
              phone_code: flags.filter((el) =>
                el.code.match(user.user.prefix || "0000")
              )[0] || {
                flag_code: "in",
                code: "+91",
                name: "India",
              },
              // {
              //   flag_code: "in",
              //   code: "+91",
              //   name: "India",
              // },
            });
            MixPanelSignInComplete({
              is_first_time_user: !user.address.address,
              sign_in_method: "Email/Mobile",
            });
            recordOrder(
              token,
              {
                ...cart_data,
                stage: "Post-OTP",
                prefix: phone_code.code.replaceAll("+", ""),
              },
              ({ status, message }) => {},
              (err) => {}
            );
            let user_data = user;
            console.log(user_data);
            console.log(user_data.intake.reproductive_health.goal);
            MixPanelUsertype({
              user_id: user_data._id || "",
              name: `${user_data.user.first_name || ""} ${
                user_data.user.last_name || ""
              }`.trim(),
              date_of_birth: user_data.user.dob || "",
              user_current_goal:
                user_data.intake.reproductive_health.goal || "",
              is_paid_user:
                !!user_data.intake.personalize.where_did_you_hear_about_us ||
                "",
              reported_symptoms:
                user_data.intake.menstrual_health.experience_symptoms || "",
              reported_conditions:
                user_data.intake.menstrual_health.symptoms || "",
              is_ultrasound_done:
                user_data.intake.reproductive_health.ultrasound_diagnosis || "",
            });
            // MixPanelOtpVerified(userData.phone);
          } else {
            setopt_error(true);
            if (message) {
              toast.error(message);
            }
          }
        },
        (err) => {
          // //
        }
      );
      // }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otpValues[index] && index > 0) {
      // Move focus to the previous input field
      const previousInput = document.getElementsByName(`otp-${index - 1}`)[0];
      previousInput.focus();
    }
  };

  const [Razorpay] = useRazorpay();
  const handelPayment = (razorpay_order_id) => {
    setis_loading(true);
    let product_ids = cart_data.cart
      .map((el) => [
        el.product_id,
        ...(el.contains.length ? el.contains.map((el1) => el1.product_id) : ""),
      ])
      .flat();
    if (
      (computed_cart.computedValues.subTotal || 0) -
        (computed_cart.computedValues.total_discount || 0) <=
      0
    ) {
      recordOrder(
        userData.token,
        {
          ...cart_data,
          user: {
            ...cart_data.user,

            address: {
              ...cart_data.user.address,
            },
          },
          stage: "Post-Payment",
          prefix: phone_code.code.replaceAll("+", ""),
          payment: {
            razorpay_payment_id: "free",
            // ...res1,
          },
        },
        ({ status, order }) => {
          if (status === "Success") {
            let test = order.filter((el) => el.product_type === "test")[0];
            let consultOrCoaching = order.filter(
              (el) =>
                el.product_type === "consult" || el.product_type === "coaching"
            )[0];

            // localStorage.setItem(
            //   "next_redirect",
            //   (window.location.host.match("localhost")
            //     ? "http://localhost:9001"
            //     : "https://app.arva.health") +
            //     "/auto-login?token=" +
            //     userData.token +
            //     "&goTo=" +
            //     (test._id
            //       ? "bookingslot"
            //       : consultOrCoaching._id
            //       ? "bookingsapplointment"
            //       : "home") +
            //     "&id=" +
            //     (test._id
            //       ? test._id
            //       : consultOrCoaching._id
            //       ? consultOrCoaching._id
            //       : ""),
            //   "_self"
            // );
            // dispatch({
            //   type: "setAppState",
            //   subType: "cart",
            //   action: "clear",
            //   payload: {},
            // });
            // navigate(
            //   "/purchase?product=" +
            //     (product_ids ? product_ids.join(",") : "") +
            //     "&total=" +
            //     cart_data.amount.gross.toFixed(2) +
            //     "&discount=" +
            //     cart_data.amount.discount.value.toFixed(2)
            // );
            localStorage.setItem(
              "next_redirect",
              (window.location.host.match("localhost")
                ? "http://localhost:9001"
                : "https://app.arva.health") +
                "/auto-login?token=" +
                userData.token +
                "&goTo=home"
            );
            dispatch({
              type: "setAppState",
              subType: "cart",
              action: "clear",
              payload: {},
            });
            navigate(
              "/purchase?product=" +
                (product_ids ? product_ids.join(",") : "") +
                "&total=" +
                cart_data.amount.gross.toFixed(2) +
                "&discount=" +
                cart_data.amount.discount.value.toFixed(2)
            );
          } else {
            toast.error("Payment UnSuccessfull Try Again");
          }
        },
        (err) => {}
      );
    } else {
      let mixpaneldata = {
        is_discount_applied: !!cart_data.amount.discount.details.name,
        order_type: cart_data.cart.map(
          (el) =>
            ({
              ft: "Lab Test",
              bc: "Doctor Consult",
              hc: "Coaching",
              ab: "Bundle",
              gu: "Guide",
            }[el.product_id.slice(0, 2).toLowerCase()])
        ),
        code: cart_data.amount.discount.details.name || "",
        order_value: cart_data.amount.gross || 0,
        total_products: cart_data.cart.length,
        cart: cart_data.cart.map((el) => el.product_id),
        is_first_time_user: !cart_data.user.address.address,
      };
      console.clear();

      console.log("here1=>", razorpay_order_id);

      const options = {
        key: "rzp_live_hC63XDouEG5lpp",
        // key: "rzp_test_tUUE2dvnAj4tCr",
        // key: "rzp_test_s7yQc89u78VsKQ",
        // amount: 100,
        // ((computed_cart.computedValues.subTotal || 0) -
        //   (computed_cart.computedValues.total_discount || 0)) *
        // 100
        currency: "INR",
        name: "Arva Health Order",
        description: "",
        image: "",
        order_id: razorpay_order_id,
        modal: {
          onDismiss: () => {
            // setrazorpay_start(false);
            setis_loading(false);
            // setuserData((prev) => ({ ...prev, razorpay_order_id: "" }));
          },
        },
        handler: (res1) => {
          if (res1.razorpay_payment_id) {
            MixPanelBookingCompleted({
              ...mixpaneldata,
            });
            if (cart_data.cart) {
              cart_data.cart.map((el) => {
                const fb_defaults = {
                  email: userData.email,
                  phone: userData.phone,
                  id: el.product_id,
                  test_name: el.product_name,
                  discount_code: cart_data.amount.discount.details.name,
                  discount_value: cart_data.amount.discount.details.value,
                  discount_percentage:
                    cart_data.amount.discount.details.is_percent,
                  price: el.product_price,
                  total_discount: cart_data.amount.discount.details.value,
                  value: el.product_price,
                };
                return Fb(
                  {
                    ...fb_defaults,
                    currency: "INR",
                    contents: {
                      ...fb_defaults,
                    },
                  },
                  "Purchase"
                );
              });
            }
            ReactGA.event({
              category: "Purchase",
              action: "Completed",
              value: parseInt(
                parseFloat(cart_data.amount.discount.gross || 0) * 100
              ),
              email: cart_data.user.user.email || "",
              phone: cart_data.user.user.email || "",
              discount_code: cart_data.amount.discount.details.name || "",
              discount_value: cart_data.amount.discount.details.value || 0,
              discount_percentage: cart_data.amount.discount.details.is_percent,
              price: cart_data.amount.discount.gross,
              total_discount: cart_data.amount.discount.details.value || 0,
              currency: "INR",
            });
            ReactGA.event({
              category: "Checkout",
              action: "Completed",
              value: parseInt(
                parseFloat(cart_data.amount.discount.gross || 0) * 100
              ),
              email: cart_data.user.user.email || "",
              phone: cart_data.user.user.email || "",
              discount_code: cart_data.amount.discount.details.name || "",
              discount_value: cart_data.amount.discount.details.value || 0,
              discount_percentage: cart_data.amount.discount.details.is_percent,
              price: cart_data.amount.discount.gross,
              total_discount: cart_data.amount.discount.details.value || 0,
              currency: "INR",
            });
            recordOrder(
              userData.token,
              {
                ...cart_data,
                user: {
                  ...cart_data.user,

                  address: {
                    ...cart_data.user.address,
                    // apt: cart_data?user.address.apartment,
                  },
                },
                stage: "Post-Payment",
                prefix: phone_code.code.replaceAll("+", ""),
                payment: {
                  razorpay_payment_id: razorpay_order_id,
                  ...res1,
                },
              },
              ({ status, order }) => {
                if (status === "Success") {
                  localStorage.setItem(
                    "next_redirect",
                    (window.location.host.match("localhost")
                      ? "http://localhost:9001"
                      : "https://app.arva.health") +
                      "/auto-login?token=" +
                      userData.token +
                      "&goTo=home"
                  );
                  dispatch({
                    type: "setAppState",
                    subType: "cart",
                    action: "clear",
                    payload: {},
                  });
                  navigate(
                    "/purchase?product=" +
                      (product_ids ? product_ids.join(",") : "") +
                      "&total=" +
                      cart_data.amount.gross.toFixed(2) +
                      "&discount=" +
                      cart_data.amount.discount.value.toFixed(2)
                  );

                  // let test = order.filter(
                  //   (el) => el.product_type === "test"
                  // )[0];
                  // let consultOrCoaching = order.filter(
                  //   (el) =>
                  //     el.product_type === "consult" ||
                  //     el.product_type === "coaching"
                  // )[0];

                  // localStorage.setItem(
                  //   "next_redirect",
                  //   (window.location.host.match("localhost")
                  //     ? "http://localhost:9001"
                  //     : "https://app.arva.health") +
                  //     "/auto-login?token=" +
                  //     userData.token +
                  //     "&goTo=" +
                  //     (test._id
                  //       ? "bookingslot"
                  //       : consultOrCoaching._id
                  //       ? "bookingsapplointment"
                  //       : "home") +
                  //     "&id=" +
                  //     (test._id
                  //       ? test._id
                  //       : consultOrCoaching._id
                  //       ? consultOrCoaching._id
                  //       : "")
                  // );
                  // navigate(
                  //   "/purchase?product=" +
                  //     (product_ids ? product_ids.join(",") : "") +
                  //     "&total=" +
                  //     cart_data.amount.gross.toFixed(2) +
                  //     "&discount=" +
                  //     cart_data.amount.discount.value.toFixed(2)
                  // );
                  // dispatch({
                  //   type: "setAppState",
                  //   subType: "cart",
                  //   action: "clear",
                  //   payload: {},
                  // });
                } else {
                  toast.error("Payment UnSuccessfull Try Again");
                }
              },
              (err) => {}
            );
            return;
          }
          toast.error("Payment Failed");
        },
        prefill: {
          // name: userData.first_name + " " + userData.last_name,
          // email: userData.email,
          contact: "+91" + userData.phone,
        },
        notes: {
          address: [
            userData.apt,
            userData.city,
            userData.address,
            userData.pincode,
          ].join(" "),
        },
        theme: {
          color: "#852f00",
        },
      };
      const rzpay = new Razorpay(options);

      rzpay.open();
      console.log("here2=>", options);
    }
  };

  useEffect(() => {
    setcart_data((prev) => ({
      ...prev,
      user: {
        user: {
          phone: userData.phone,
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
          date_of_birth: userData.date_of_birth,
          gst: userData.gst,
          dob: userData.date_of_birth,
        },
        address: {
          pin_code: userData.pincode,
          address: userData.address,
          apt: userData.apt,
          apartment: userData.apt,
          landmark: userData.landmark,
          city: userData.city,
          state: userData.state,
        },
        user_id: userData.user_id,
      },
    }));
  }, [userData]);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (addressdebo !== "") {
        getAddress(
          addressdebo,
          (success) => {
            setAddressList(success.addresses);
          },
          (error) => {}
        );
      } else {
        setAddressList([]);
      }
    }, 300);

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if ModalValue.address changes before 300ms
    };
  }, [addressdebo]);
  useLayoutEffect(() => {
    window.onclick = () => {
      setAddressList([]);
      setaddressdebo("");
    };
  }, []);

  // console.log(!/^(?:\d{5}\s\d{5})$/.test(userData.phone));

  return (
    <div className="page_main checkout">
      <Header
        {...{
          txt: (
            <svg
              className="logo"
              xmlns="http://www.w3.org/2000/svg"
              width="81"
              height="20"
              viewBox="0 0 81 20"
              fill="none"
            >
              <path
                d="M1.09588 19.83H4.09871C4.29701 19.83 4.52364 19.7167 4.60863 19.4901L6.28002 15.2125H15.8268L17.4981 19.4901C17.5831 19.7167 17.8098 19.83 18.0081 19.83H21.0109C21.4925 19.83 21.8324 19.4051 21.6341 18.9235L14.3253 0.339944C14.2404 0.113315 14.0137 0 13.8154 0H8.29135C8.09305 0 7.86642 0.113315 7.78143 0.339944L0.472652 18.9235C0.302681 19.3768 0.614295 19.83 1.09588 19.83ZM7.7531 11.5297L11.0676 2.91785L14.4103 11.5297H7.7531Z"
                fill="#5F1D1B"
              />
              <path
                d="M40.0975 20C40.5791 20 40.9474 19.915 40.9474 19.1501V16.8839C40.9474 16.4589 40.8341 16.3173 40.3808 16.289C38.9927 16.2323 38.936 13.5694 37.123 11.898C38.9077 10.7932 39.8992 8.95184 39.8992 6.60057C39.8992 2.63456 37.0947 0 32.5904 0H23.9502C23.6952 0 23.5253 0.141643 23.5253 0.424929V19.4051C23.5253 19.6601 23.6952 19.83 23.9502 19.83H27.0664C27.3496 19.83 27.4913 19.6601 27.4913 19.4051V13.2011H31.8822C36.2165 13.2011 34.3185 20 40.0975 20ZM27.4913 9.51841V3.68272H32.7321C34.7151 3.68272 35.9332 4.70255 35.9332 6.60057C35.9332 8.49858 34.7151 9.51841 32.7321 9.51841H27.4913Z"
                fill="#5F1D1B"
              />
              <path
                d="M49.4827 19.83H53.2787C53.477 19.83 53.7037 19.7167 53.7886 19.4901L61.664 0.906516C61.8623 0.424929 61.5224 0 61.0408 0H57.8396C57.6413 0 57.4147 0.113315 57.3297 0.339944L51.3807 15.2691L45.4317 0.339944C45.3467 0.113315 45.1201 0 44.9218 0H41.7207C41.2391 0 40.8991 0.424929 41.0974 0.906516L48.9728 19.4901C49.0578 19.7167 49.2844 19.83 49.4827 19.83Z"
                fill="#5F1D1B"
              />
              <path
                d="M59.982 19.83H62.9848C63.1831 19.83 63.4097 19.7167 63.4947 19.4901L65.1661 15.2125H74.7129L76.3842 19.4901C76.4692 19.7167 76.6959 19.83 76.8942 19.83H79.897C80.3786 19.83 80.7185 19.4051 80.5202 18.9235L73.2114 0.339944C73.1265 0.113315 72.8998 0 72.7015 0H67.1775C66.9791 0 66.7525 0.113315 66.6675 0.339944L59.3588 18.9235C59.1888 19.3768 59.5004 19.83 59.982 19.83ZM66.6392 11.5297L69.9537 2.91785L73.2964 11.5297H66.6392Z"
                fill="#5F1D1B"
              />
            </svg>
          ),
          back: true,
        }}
      />

      <div className="body">
        <div className="title">Your details</div>
        <div className="input_collection">
          {[
            {
              key: "first_name",
              title: "First Name",
              placeholder: "First Name",
              is_read: checkoutStep != 0,
            },
            {
              key: "last_name",
              title: "Last Name",
              // placeholder: "Bajaj",
              placeholder: "Last Name",
              is_read: checkoutStep != 0,
            },
          ].map(
            (
              { is_read, suffix, prefix, key, title, placeholder, validation },
              i
            ) => (
              <CustInputMuiClone
                {...{
                  unique_id: "inp1" + i,
                  attr: {
                    readonly: is_read,
                    error: userDataerr[key],
                    error_msg: "",
                    value: userData[key],
                    inputMode: "text",
                    event: {
                      change: ({ target }) => {
                        if (validation) {
                          validation(
                            target.value.replace(/[^a-zA-Z]/g, "") || ""
                          );
                        } else {
                          setuserData((prev) => ({
                            ...prev,
                            [key]: target.value.replace(/[^a-zA-Z]/g, ""),
                          }));
                          setuserDataerr((prev) => ({
                            ...prev,
                            [key]:
                              target.value.replace(/[^a-zA-Z]/g, "").trim()
                                .length == 0,
                          }));
                        }
                      },
                      onkeydown: (e) => {
                        if (e.key == "Enter") {
                        }
                      },
                    },
                  },
                  data: {
                    //   suffix: suffix || "",
                    prefix: prefix || "",
                    suffix: is_read ? "" : "",
                    ...(input_title
                      ? {
                          title,
                        }
                      : {
                          placeholder,
                        }),
                  },
                }}
              />
            )
          )}

          {[1].includes(checkoutStep) && (
            <>
              <div className="otp_group">
                <div className="otp_header">
                  <div className="sent_to">
                    OTP sent to +91 {userData.phone} and {userData.email}
                  </div>
                  {svg("pencil", "", () => setcheckoutStep(0))}
                </div>
                <div className="opt_stand">
                  {otpValues.map((value, index) => (
                    <input
                      key={index}
                      type="number"
                      maxLength="1"
                      className={
                        "opt_design " +
                        // (otp != value ? "opt_design_active " :"") +
                        (value != "" ? "opt_design_active " : "") +
                        (opt_error ? " error_o" : "")
                      }
                      inputMode="numeric"
                      name={`otp-${index}`}
                      placeholder=""
                      value={value}
                      onChange={(e) => {
                        handleInputChange(index, e.target.value);
                        setopt_error(false);
                      }}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
          {/* <PhoneInput
            country={"in"}
            value={userData.phone}
            onChange={(phone) =>
              setuserData((prev) => ({ ...prev, phone: phone }))
            }
            // enableSearch={true}
          /> */}
          {/* <CustPhoneInput
         
            {...{
              i: 1,
              userData,
              setuserData,
              userDataerr,
              setcheckoutStep,
              setOtpValues,
              // input_title,

              userData,
              setuserDataerr,
              key: "phone",
              title: "Phone",
              placeholder: "98450 13636",
              //   suffix: "flag",
              prefix: svg("in_flag"),
              is_read: checkoutStep != 0,
              validation: (value) => {
                if (Number(value.slice(-1)).toString() == "NaN") {
                  return;
                }
                const cleanedInput = value.replace(/\D/g, "");
                let formattedInput = "";
                if (cleanedInput.length > 5) {
                  formattedInput =
                    cleanedInput.slice(0, 5) + " " + cleanedInput.slice(5);
                } else {
                  formattedInput = cleanedInput;
                }
                if (cleanedInput.length === 11) {
                  return;
                }
                setuserData((prev) => ({
                  ...prev,
                  phone: formattedInput.slice(0, 11),
                }));
                setuserDataerr((prev) => ({
                  ...prev,
                  phone: formattedInput.length == 11 ? "" : "Error message",
                }));
              },
            }}
          /> */}
          {[0, 2, 3, 4].includes(checkoutStep)
            ? [
                {
                  key: "email",
                  title: "Email",
                  placeholder: "Dipalie",
                  placeholder: "Email",
                  is_read: checkoutStep != 0,
                  suffix: "test",
                  validation: (value) => {
                    setuserData((prev) => ({
                      ...prev,
                      email: value.toLowerCase(),
                    }));
                    setuserDataerr((prev) => ({
                      ...prev,
                      email: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
                        value.trim()
                      ),
                    }));
                  },
                },
                {
                  key: "phone",
                  title: "Phone",
                  placeholder: "98450 13636",
                  placeholder: "Phone",
                  cust_class: "cust_phone_input_main",
                  //   suffix: "flag",
                  prefix: (
                    <CustPhoneCountries
                      value={userData.phone_code}
                      setvalue={setphone_code}
                    />
                  ),
                  is_read: checkoutStep != 0,
                  validation: (value) => {
                    if (Number(value.slice(-1)).toString() == "NaN") {
                      return;
                    }
                    const cleanedInput = value.replace(/\D/g, "");
                    let formattedInput = "";
                    if (cleanedInput.length > 5) {
                      formattedInput =
                        cleanedInput.slice(0, 5) + " " + cleanedInput.slice(5);
                    } else {
                      formattedInput = cleanedInput;
                    }
                    if (cleanedInput.length === 11) {
                      return;
                    }
                    setuserData((prev) => ({
                      ...prev,
                      phone: formattedInput.slice(0, 11),
                    }));
                    setuserDataerr((prev) => ({
                      ...prev,
                      phone: formattedInput.length == 11 ? "" : "Error message",
                    }));
                  },
                },
              ].map(
                (
                  {
                    cust_class,
                    is_read,
                    suffix,
                    prefix,
                    key,
                    title,
                    placeholder,
                    validation,
                  },
                  i
                ) => (
                  <CustInputMuiClone
                    {...{
                      unique_id: "inp2" + i,
                      attr: {
                        class: cust_class || "",
                        // readonly: is_read,
                        error: userDataerr[key],
                        error_msg: "",
                        value: userData[key],
                        inputMode: "text",
                        event: {
                          change: ({ target }) => {
                            setcheckoutStep(0);
                            setOtpValues(["", "", "", ""]);
                            if (validation) {
                              validation(target.value || "");
                            } else {
                              setuserData((prev) => ({
                                ...prev,
                                [key]: target.value,
                              }));
                              setuserDataerr((prev) => ({
                                ...prev,
                                [key]: target.value.trim().length == 0,
                              }));
                            }
                          },
                          onkeydown: (e) => {
                            if (e.key == "Enter") {
                            }
                          },
                        },
                      },
                      data: {
                        //   suffix: suffix || "",
                        prefix: prefix || "",
                        suffix: is_read ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.707 6.707C11.8892 6.5184 11.99 6.2658 11.9877 6.0036C11.9854 5.7414 11.8802 5.49059 11.6948 5.30518C11.5094 5.11977 11.2586 5.0146 10.9964 5.01233C10.7342 5.01005 10.4816 5.11084 10.293 5.293L7 8.586L5.707 7.293C5.5184 7.11084 5.2658 7.01005 5.0036 7.01233C4.7414 7.0146 4.49059 7.11977 4.30518 7.30518C4.11977 7.49059 4.0146 7.7414 4.01233 8.0036C4.01005 8.2658 4.11084 8.5184 4.293 8.707L6.293 10.707C6.48053 10.8945 6.73484 10.9998 7 10.9998C7.26516 10.9998 7.51947 10.8945 7.707 10.707L11.707 6.707Z"
                              fill="#3A7D23"
                            />
                          </svg>
                        ) : (
                          ""
                        ),
                        ...(input_title
                          ? {
                              title,
                            }
                          : {
                              placeholder,
                            }),
                      },
                    }}
                  />
                )
              )
            : ""}
          {[2, 3, 4].includes(checkoutStep) && (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  maxDate={dayjs().subtract(18, "year")}
                  className={
                    "cust_input" +
                    (userDataerr.date_of_birth ? " inp_error" : "")
                  }
                  sx={{
                    width: "100%",
                    outline: 0,
                    "&.MuiTextField-root": {
                      display: "flex",
                      flexDirection: "column-reverse",
                      "&:focus-within>label": {
                        display: "block",
                      },
                    },
                    "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                      // border: "0 !important",
                      border: "1.5px solid var(--gray-g-200)",
                      borderRadius: "5px",
                    },
                    " label": {
                      //   position: "unset",
                      //   transform: "unset",
                      //   display: "none",
                      color: "#777777 !important",
                      //   padding: "6px 0 0 0",
                      //   fontSize: "14px",
                    },
                    "& input": {
                      color: "#000",
                      fontWeight: 500,
                    },
                    // "& input:focus-visible~.MuiOutlinedInput-notchedOutline": {
                    //   // outline: "2px solid var(--brand-peach-soda)",
                    // },
                    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                      border: "1.5px solid var(--red) !important",
                      outline: "0px !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "var(--brand-peach-soda) !important",
                      borderWidth: "2px !important",
                    },
                  }}
                  value={
                    userData.date_of_birth
                      ? dayjs(new Date(userData.date_of_birth))
                      : null
                  }
                  onChange={(value) => {
                    setuserData((prev) => ({ ...prev, date_of_birth: value }));
                    setuserDataerr((prev) => ({
                      ...prev,
                      date_of_birth: !value || value?.$d == "Invalid Date",
                    }));
                  }}
                  slotProps={{
                    textField: {
                      error: userDataerr.date_of_birth, // Controls the error state
                    },
                  }}
                  onMouseEneter={(value) => {
                    console.log("date focus");
                  }}
                  format="DD/MM/YYYY"
                  label="Date of birth"
                />
              </LocalizationProvider>
            </>
          )}

          {[2, 3, 4].includes(checkoutStep) &&
            [
              {
                key: "pincode",
                title: "Pincode",
                placeholder: "Pincode",
                // is_read: checkoutStep != "step1",
                error: userDataerr.pincode,
                suffix:
                  checkoutStep == 4 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.707 6.707C11.8892 6.5184 11.99 6.2658 11.9877 6.0036C11.9854 5.7414 11.8802 5.49059 11.6948 5.30518C11.5094 5.11977 11.2586 5.0146 10.9964 5.01233C10.7342 5.01005 10.4816 5.11084 10.293 5.293L7 8.586L5.707 7.293C5.5184 7.11084 5.2658 7.01005 5.0036 7.01233C4.7414 7.0146 4.49059 7.11977 4.30518 7.30518C4.11977 7.49059 4.0146 7.7414 4.01233 8.0036C4.01005 8.2658 4.11084 8.5184 4.293 8.707L6.293 10.707C6.48053 10.8945 6.73484 10.9998 7 10.9998C7.26516 10.9998 7.51947 10.8945 7.707 10.707L11.707 6.707Z"
                        fill="#3A7D23"
                      />
                    </svg>
                  ) : pincode_loading ? (
                    <div className="svg_floater_main">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 64 65"
                        fill="none"
                      >
                        <path
                          d="M64 32.9972C64 50.6712 49.674 64.9972 32 64.9972C14.326 64.9972 0 50.6712 0 32.9972C0 15.3232 14.326 0.997192 32 0.997192V4.99719C16.536 4.99719 4 17.5332 4 32.9972C4 48.4612 16.536 60.9972 32 60.9972C47.464 60.9972 60 48.4612 60 32.9972H64Z"
                          fill="#5F1D1B"
                          // fill="#1D1D1D"
                        />
                      </svg>
                    </div>
                  ) : (
                    ""
                  ),
                validation: (value) => {
                  setcheckoutStep(3);
                  if (Number(value.slice(-1)).toString() == "NaN") {
                    return;
                  }

                  setuserData((prev) => ({
                    ...prev,
                    pincode: value.slice(0, 6),
                  }));
                  setuserDataerr((prev) => ({
                    ...prev,
                    pincode: value.length == 6 ? "" : "Error message",
                  }));
                },
              },
            ].map(
              (
                {
                  is_read,
                  suffix,
                  prefix,
                  key,
                  title,
                  placeholder,
                  validation,
                  error,
                },
                i
              ) => (
                <CustInputMuiClone
                  {...{
                    unique_id: "inp3" + i,
                    attr: {
                      readonly: is_read,
                      error: error ? error : "",
                      error_msg: "",
                      value: userData[key],
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          if (validation) {
                            return validation(target.value);
                          }
                          setuserData((prev) => ({
                            ...prev,
                            [key]: target.value,
                          }));
                        },
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      //   suffix: suffix || "",
                      prefix: prefix || "",
                      suffix: suffix || "",
                      ...(input_title
                        ? {
                            title,
                          }
                        : {
                            placeholder,
                          }),
                    },
                  }}
                />
              )
            )}
          {[4].includes(checkoutStep) &&
            [
              {
                key: "address",
                title: "Address",
                placeholder: "Address",
                // error: true,
                // is_read: checkoutStep != "step1",
              },
            ].map(
              (
                {
                  is_read,
                  suffix,
                  prefix,
                  key,
                  title,
                  placeholder,
                  validation,
                  error,
                  redonly,
                },
                i
              ) => (
                <div className="address_input">
                  <CustInputMuiClone
                    {...{
                      unique_id: "inp3" + i,
                      attr: {
                        readonly: is_read,
                        // error: error ? error : "",
                        error: userDataerr[key],
                        error_msg: "",
                        value: userData[key],
                        inputMode: "text",
                        event: {
                          change: ({ target }) => {
                            if (validation) {
                              return validation(target.value);
                            }
                            if (key == "address") {
                              setaddressdebo(target.value);
                            }
                            setuserData((prev) => ({
                              ...prev,
                              [key]: target.value,
                            }));
                            setuserDataerr((prev) => ({
                              ...prev,
                              [key]: !target.value,
                            }));
                          },
                          onkeydown: (e) => {
                            if (e.key == "Enter") {
                            }
                          },
                        },
                      },
                      data: {
                        //   suffix: suffix || "",
                        prefix: prefix || "",
                        suffix: is_read ? "" : "",
                        ...(input_title
                          ? {
                              title,
                            }
                          : {
                              placeholder,
                            }),
                      },
                    }}
                  />
                  {AddressList.length ? (
                    <div className="add_input_list">
                      {AddressList?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setuserData((prev) => ({
                              ...prev,
                              address: item,
                            }));

                            setaddressdebo("");
                            setAddressList([]);
                          }}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )
            )}
          {[4].includes(checkoutStep) &&
            [
              // {
              //   key: "address",
              //   title: "Address",
              //   placeholder: "Address",
              //   error: true,
              //   // is_read: checkoutStep != "step1",
              // },
              {
                key: "apt",
                title: "House / Apt / Floor",
                placeholder: "House / Apt / Floor",
                error: true,
                // is_read: checkoutStep != "step1",
              },
              {
                key: "landmark",
                title: "Landmark",
                placeholder: "Landmark",
                error: true,
                // is_read: checkoutStep != "step1",
              },
              {
                key: "city",
                title: "City",
                placeholder: "City",
                is_read: true,
                // is_read: checkoutStep != "step1",
              },
              {
                key: "state",
                title: "State",
                placeholder: "State",
                is_read: true,
                // is_read: checkoutStep != "step1",
              },
              {
                key: "gst",
                title: "GST number (Optional)",
                placeholder: "GST number (Optional)",
                // is_read: checkoutStep != "step1",
              },
            ].map(
              (
                {
                  is_read,
                  suffix,
                  prefix,
                  key,
                  title,
                  placeholder,
                  validation,
                  error,
                  redonly,
                },
                i
              ) => (
                <CustInputMuiClone
                  {...{
                    unique_id: "inp3" + i,
                    attr: {
                      readonly: is_read,
                      // error: error ? error : "",
                      error: userDataerr[key],
                      error_msg: "",
                      value: userData[key],
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          if (validation) {
                            return validation(target.value);
                          }
                          if (key == "address") {
                            setaddressdebo(target.value);
                          }
                          setuserData((prev) => ({
                            ...prev,
                            [key]: target.value,
                          }));
                          if (key != "gst") {
                            setuserDataerr((prev) => ({
                              ...prev,
                              [key]: !target.value,
                            }));
                          }
                        },
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      //   suffix: suffix || "",
                      prefix: prefix || "",
                      suffix: is_read ? "" : "",
                      ...(input_title
                        ? {
                            title,
                          }
                        : {
                            placeholder,
                          }),
                    },
                  }}
                />
              )
            )}
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div
            className="btn"
            onClick={() => {
              const {
                first_name,
                last_name,
                email,
                phone,
                date_of_birth,
                pincode,
                address,
                apt,
                landmark,
                city,
                gst,
              } = userDataerr;
              // // //
              // //
              // return;
              switch (checkoutStep) {
                case 0:
                  if (
                    first_name ||
                    last_name ||
                    email ||
                    phone ||
                    userData.first_name.trim().length == 0 ||
                    userData.last_name.trim().length == 0 ||
                    userData.email.trim().length == 0 ||
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
                      userData.email.trim()
                    ) ||
                    userData.phone.trim().length == 0 ||
                    !/^(?:\d{5}\s\d{5})$/.test(userData.phone.trim())
                  ) {
                    setuserDataerr((prev) => ({
                      ...prev,
                      first_name: userData.first_name.trim().length == 0,
                      last_name: userData.last_name.trim().length == 0,
                      email:
                        userData.email.trim().length == 0 ||
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
                          userData.email.trim()
                        ),
                      phone:
                        userData.phone.trim().length == 0 ||
                        !/^(?:\d{5}\s\d{5})$/.test(userData.phone),
                    }));
                    return; // console.warn("error step 0");
                  } else {
                    sendOtp(
                      {
                        prefix: phone_code.code.replaceAll("+", ""),
                        phone: userData.phone.replaceAll(" ", "").trim(),
                        first_name: userData.first_name.trim() || "",
                      },
                      ({ status, message }) => {
                        if (status) {
                          setcheckoutStep(1);
                          if (message) {
                            toast.success(message);
                          }
                        }
                      },
                      (err) => {
                        // //
                      }
                    );
                  }
                  return;
                case 1:
                  if (otpValues?.join("")?.length < 4) {
                    setopt_error(true);
                  }
                  return;
                case 2:
                  if (userData.pincode.length == 6) {
                    verifyOtp(
                      {
                        prefix: phone_code.code.replaceAll("+", ""),
                        phone: userData.phone.replaceAll(" ", "").trim(),
                        first_name: userData.first_name,
                        last_name: userData.last_name,
                        email: userData.email,
                        otp: otpValues.join(""),
                      },
                      ({ status, message, token }) => {
                        if (status) {
                          setuserData((prev) => ({ ...prev, token }));
                          setcheckoutStep(3);
                          if (message) {
                            toast.success(message);
                          }
                          recordOrder(
                            token,
                            {
                              ...cart_data,
                              stage: "Post-OTP",
                              prefix: phone_code.code.replaceAll("+", ""),
                            },

                            ({ status, message }) => {},
                            (err) => {}
                          );
                        } else {
                          if (message) {
                            toast.error(message);
                          }
                        }
                      },
                      (err) => {
                        // //
                      }
                    );
                  }
                  return;
                case 3:
                  // setpincode_loading(true);
                  // checkPincode(
                  //   userData.pincode,
                  //   [
                  //     userData.apt,
                  //     userData.address,
                  //     userData.city,
                  //     userData.state,
                  //   ],
                  //   userData.token,
                  //   ({ api }) => {
                  //     setpincode_loading(false);
                  //     // if (api) {
                  //     setuserDataerr((prev) => ({
                  //       ...prev,
                  //       pincode: !api,
                  //     }));
                  //     if (api) {
                  //       setcheckoutStep(4);
                  //       setuserData((prev) => ({
                  //         ...prev,
                  //         is_pincode_validated: true,
                  //       }));
                  //       recordOrder(
                  //         userData.token,
                  //         {
                  //           ...cart_data,
                  //           stage: "Post-Pin-Code",
                  //           prefix: phone_code.code.replaceAll("+", ""),
                  //         },

                  //         ({ status, message }) => {},
                  //         (err) => {}
                  //       );
                  //     } else {
                  //       setcheckoutStep(3);
                  //       setuserData((prev) => ({
                  //         ...prev,
                  //         is_pincode_validated: false,
                  //       }));
                  //     }
                  //     // //
                  //     // return;
                  //     // }
                  //   },
                  //   (error) => {
                  //     setuserData((prev) => ({
                  //       ...prev,
                  //       is_pincode_validated: false,
                  //     }));
                  //     // //
                  //   }
                  // );
                  return;
                case 4:
                  if (
                    userData.address.trim() &&
                    userData.apt.trim() &&
                    userData.landmark.trim() &&
                    userData.date_of_birth &&
                    // userData.city.trim() &&
                    !date_of_birth
                    // &&
                    // (!userData.gst ||
                    //   (userData.gst &&
                    //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
                    //       userData.gst
                    //     )))
                  ) {
                    // console.log("working");
                    // return;
                    recordOrder(
                      userData.token,
                      {
                        ...cart_data,
                        user: {
                          ...cart_data.user,
                          address: {
                            ...cart_data.user.address,
                            // apt: cart_data?user.address.apartment,
                          },
                        },
                        stage: "Pre-Payment",
                        prefix: phone_code.code.replaceAll("+", ""),
                      },

                      ({
                        status,
                        lead: {
                          payment: { razorpay_order_id },
                        },
                      }) => {
                        console.log({ razorpay_order_id });

                        setuserData((prev) => ({
                          ...prev,
                          razorpay_order_id,
                        }));
                        handelPayment(razorpay_order_id);
                      },
                      (err) => {}
                    );
                  } else {
                    // console.log("no-working");
                    setuserDataerr((prev) => ({
                      ...prev,
                      address: !userData.address.trim(),
                      apt: !userData.apt.trim(),
                      landmark: !userData.landmark.trim(),
                      date_of_birth: date_of_birth || !userData.date_of_birth,
                      // city: !userData.city.trim(),
                      // gst:
                      //   userData.gst.trim() != "" ||
                      //   (userData.gst.trim() != "" &&
                      //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
                      //       userData.gst.trim()
                      //     )),
                    }));
                  }

                  return;
                default:
                  break;
              }
            }}
          >
            {checkoutStep == 4
              ? `Pay | ₹${
                  (computed_cart.computedValues.subTotal || 0) -
                    (computed_cart.computedValues.total_discount || 0) <
                  0
                    ? 0
                    : parseInt(
                        (computed_cart.computedValues.subTotal || 0) -
                          (computed_cart.computedValues.total_discount || 0)
                      )
                }`
              : "Continue"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
