import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/cart");
  }, []);

  return <div>Login</div>;
};

export default Login;
