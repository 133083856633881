import React from "react";
import BlockContent from "@sanity/block-content-to-react";

function asset(url) {
  let a = url.split("-");
  return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
    .slice(1, -1)
    .join("-")}.${a[a.length - 1]}`;
}

const serializers = {
  types: {
    block: (props) => {
      //
      switch (props.node.style) {
        case "h1":
          return <h1>{props.children}</h1>;
        case "h2":
          return <h2>{props.children}</h2>;
        case "h3":
          return <h3>{props.children}</h3>;
        case "h4":
          return <h4>{props.children}</h4>;
        case "h5":
          return <h5>{props.children}</h5>;
        case "h6":
          return <h6>{props.children}</h6>;
        case "span":
          return <span>{props.children}</span>;
        case "blockquote":
          return <blockquote>{props.children}</blockquote>;
        default:
          return <p className="desc">{props.children}</p>;
      }
    },
    image: (props) => (
      <figure>
        <img
          src={asset(props.node.asset._ref)}
          alt={props.node.alt}
          style={{ maxWidth: "100%" }}
        />
        {props.node.caption && (
          <figcaption className="img_stand_box_desc">
            {" "}
            {props.node.caption}
          </figcaption>
        )}
      </figure>
    ),
    video: (props) => (
      <div>
        <iframe
          src={props.node.url}
          title={props.node.caption}
          frameBorder="0"
          allowFullScreen
        ></iframe>
        {props.node.caption && <p>{props.node.caption}</p>}
      </div>
    ),
    audio: (props) => (
      <div>
        <audio controls src={props.node.url}></audio>
        {props.node.caption && <p>{props.node.caption}</p>}
      </div>
    ),
    externalLink: (props) => (
      <a href={props.node.url} target="_blank" rel="noopener noreferrer">
        {props.node.text}
      </a>
    ),
    file: (props) => (
      <a href={props.node.asset.url} target="_blank" rel="noopener noreferrer">
        Download File
      </a>
    ),
    location: (props) => (
      <div>
        Location: {props.node.lat}, {props.node.lng}
      </div>
    ),
    number: (props) => (
      <div>
        {props.node.lat}, {props.node.lng}
      </div>
    ),
  },
  marks: {
    strong: (props) => <strong>{props.children}</strong>,
    em: (props) => <em>{props.children}</em>,
    code: (props) => <code>{props.children}</code>,
    underline: (props) => <u>{props.children}</u>,
    "strike-through": (props) => <s>{props.children}</s>,
    link: (props) => (
      <a
        href={props.mark.href}
        target={props.mark.blank ? "_blank" : "_self"}
        rel="noopener noreferrer"
        className="ancker_data"
      >
        {props.children}
      </a>
    ),
    internalLink: (props) => {
      // You might want to use a router link here if you're using a framework like Next.js or Gatsby
      return (
        <a className="ancker_data" href={`/article/${props.mark._ref}`}>
          {props.children}
        </a>
      );
    },
  },
};

export const SanityContent = ({ content }) => {
  //
  return <BlockContent blocks={content} serializers={serializers} />;
};
