import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
// import { utilsArray } from "../../utils/utils";

// const project_token = "a118513275c37393066b01c1d2a13da3"; //test
const project_token = "18bf1697859093af3fa810b39fb37680"; //prod
// const project_id = "3115909"; // TEST
// const project_id = "3116900"; // PROD
mixpanel.init(project_token);
// const mixpanelInstance = mixpanel.init(project_token);

export const Fb = (obj, endpt) => {
  window.fbq("trackCustom", endpt, {
    ...obj,
  });
};

export const Fb1 = (obj, endpt) => {
  window.fbq("trackCustom", endpt, {
    ...obj,
    contents: {
      ...obj,
    },
  });
};

export const MixPanelProductAdd = (data, callback) => {
  try {
    mixpanel.track("Order Button Tapped", { ...data });
    Fb1({ ...data }, "Order Button Tapped");
  } catch (err) {}
};

export const MixPanelDiscount = (data, callback) => {
  try {
    mixpanel.track("Discount Button tapped", {
      ...data,
    });
    Fb1({ ...data }, "Disount Button tapped");
  } catch (err) {}
};

export const userProperties = (data) => {
  mixpanel.people.set({
    "Sign Up Date": data?.sign_up_date || "N/A",
    "First Visit Date": data?.sign_up_date || "N/A",

    "User ID": data?.user_id || "N/A",
    Name: data?.name || "N/A",
    "Date Of Birth": data?.date_of_birth || "N/A",
    "User Current Goal": data?.user_current_goal || "N/A",
    "Is Paid User": data?.is_paid_user,
    "Reported Symptoms": data?.reported_symptoms || "N/A",
    "Reported Conditions": data?.reported_conditions || "N/A",
    "Is Ultrasound Done": data?.is_ultrasound_done,

    Gender: "Female",
    "Is Test User": false,

    "Is Birth Control": data?.is_birth_control,
  });
  mixpanel.register({
    "Sign Up Date": data.sign_up_date || "N/A",
    "Is Paid User": data.is_paid_user || "N/A",
    "Last Order Type": data.last_order_type || "N/A",
    "User Current Goal": data.user_current_goal || "N/A",
  });
};

export const MixPanelOtpTriggered = (data, callback) => {
  try {
    mixpanel.track("OTP Triggered", {
      ...data,
    });
    Fb1({ ...data }, "OTP Triggered");
  } catch {}
};
export const MixPanelOtpEntered = (data, callback) => {
  try {
    mixpanel.track("OTP Entered", {});
    Fb1({}, "OTP Entered");
  } catch {}
};
export const MixPanelSignInComplete = (data, callback) => {
  try {
    mixpanel.track("Sign In Completed", {
      ...data,
    });
    Fb1({ ...data }, "Sign In Completed");
  } catch {}
};

export const MixPanelUsertype = (success, callback) => {
  try {
    console.error("set user prorperties");
    userProperties({
      ...success,
    });
    mixpanel.identify(success?.customer?._id || "");
  } catch {}
};
export const MixPanelPincodeCheckRequested = (data, callback) => {
  try {
    mixpanel.track("Pincode Check Requested", {
      ...data,
    });
    Fb1({ ...data }, "Pincode Check Requested");
  } catch {}
};
export const MixPanelBookingCompleted = (data) => {
  try {
    mixpanel.track("Booking Completed", {
      ...data,
    });
    // Fb1({ ...data }, "Purchase");
  } catch {}
};
